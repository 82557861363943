<template>
  <v-container fluid class="navbar-line navbar-shop">
    <div class="navbar-row d-flex align-center">
      <!-- menu col -->
      <div
        class="justify-left align-center"
        v-if="$platform_is_cordova && $routerHistory.hasPrevious()"
      >
        <!-- <router-link
          class="router-back"
          active-class="noop"
          :to="{ path: $routerHistory.previous().path }"
        >
          <v-btn class="no-border" aria-label="Torna Indietro" icon>
            <v-icon class="default--text back-arrow">$arrowBack</v-icon>
          </v-btn>
        </router-link> -->
        <v-btn
          v-if="$platform_is_cordova && $routerHistory.hasPrevious()"
          @click.prevent.stop="historyBack()"
          class="back-button no-border"
          aria-label="Torna Indietro"
          icon
        >
          <v-icon class="default--text back-arrow">$arrowBack</v-icon>
        </v-btn>
      </div>
      <div
        class="menu-column menu-btn order-0 d-flex justify-start align-center column pl-3 pl-md-0 pr-md-4 pr-lg-8"
        :class="{
          is_back: $platform_is_cordova && $routerHistory.hasPrevious()
        }"
      >
        <v-btn
          v-if="site"
          aria-label="Apri menu categorie"
          icon
          class="open-menu-btn"
          @click.prevent.stop="drawerLeft = !drawerLeft"
        >
          <v-icon color="grey darken-1">$menu</v-icon>
          {{ $t("navbar.products") }}
        </v-btn>
      </div>
      <!-- logo col -->
      <div class="logo-column order-1 column">
        <router-link
          :to="site == 'site' ? { name: 'HomeSite' } : { name: 'Home' }"
          class="d-flex align-center"
        >
          <!-- logo desktop -->
          <img
            class="px-0 logo-desktop d-none d-md-flex"
            src="/logo/logo.svg"
            alt="Logo Basko"
            :style="{ height: '48px' }"
          />
          <!-- logo mobile -->
          <img
            class="px-0 logo-mobile mx-auto d-flex d-md-none"
            src="/logo/logo.svg"
            alt="Logo Basko"
            :style="{ height: '32px' }"
          />
        </router-link>
      </div>
      <!-- search col -->
      <div
        class="search-input-column order-4 order-md-2 column d-flex px-2 px-sm-0 px-md-4 px-lg-8 mt-5 mt-md-0"
      >
        <SearchInput />
      </div>
      <!-- buttons col -->
      <div
        class="buttons-column column order-2 order-md-3 d-flex justify-end align-center"
      >
        <v-layout v-if="!isAuthenticated" align-center justify-end>
          <div class="d-flex">
            <v-btn
              aria-label="Apri il carrello"
              icon
              @click.stop="handleLogin()"
              class="mr-sm-2"
            >
              <v-icon color="grey darken-1">$cart</v-icon>
            </v-btn>
            <!-- sloggato -->
            <!-- <v-btn aria-label="Vai al profilo" @click.stop="handleLogin()" icon>
              <v-icon color="grey darken-1">$profile</v-icon>
            </v-btn> -->
          </div>
        </v-layout>
        <v-layout v-else justify-end align-center>
          <!-- <v-menu bottom offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                aria-label="Vai al profilo"
                v-bind="attrs"
                v-on.stop="on"
                tile
                depressed
                color="white"
                class="profile-btn"
              >
                <v-icon color="grey darken-1">$profile</v-icon>
                <div class="username d-none d-lg-block  text-caption ml-2">
                  <span>{{ $t("navbar.userMessage") }}</span> <br />
                  <strong>{{ cart.user.firstName }}</strong>
                </div>
              </v-btn>
            </template>
            <UserMenu />
          </v-menu> -->
          <transition name="shake" mode="out-in">
            <v-btn
              :key="cart.refreshTime"
              class="cart-total-logged"
              :class="{ disabled: !editableCart }"
              @click.stop="openCart()"
              aria-label="Apri carrello"
              color="green"
              depressed
              :icon="$vuetify.breakpoint.smAndDown"
              large
            >
              <v-badge
                :content="cart.totalItems"
                :value="cart.totalItems > 0"
                :key="cart.totalItems"
                transition="bounce"
                color="green"
                overlap
                offset-x="16"
                class="d-flex flex-column"
              >
                <v-icon color="grey darken-1" class="cart-icon">
                  $cart
                </v-icon>
              </v-badge>

              <!-- <div class="cart-total d-none d-md-flex" role="button">
                <span class="default--text px-4" style="line-height:1">
                  <i18n-n :value="cart.totalPrice" format="currency" tag="span">
                    <template v-slot:literal></template>
                  </i18n-n>
                  &nbsp;-&nbsp;
                  {{ $tc("navbar.cartProducts", cart.totalItems) }}
                </span>
              </div> -->
            </v-btn>
          </transition>
        </v-layout>
      </div>
    </div>
  </v-container>
</template>
<style lang="scss">
.navbar-shop {
  .search-input-column {
    flex-grow: 2;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .navbar-row {
      flex-wrap: wrap;
    }
    .buttons-column,
    .menu-column {
      flex-basis: 23%;
    }
    .is_back {
      flex-basis: 18% !important;
      padding-left: 4px !important;
    }
    .is_back ~ .buttons-column {
      flex-basis: 18% !important;
    }
    .logo-column {
      flex-basis: 50%;
    }
    .search-input-column {
      flex-basis: 100%;
      .search-input-container {
        flex-basis: 100%;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .buttons-column {
      flex-basis: 23%;
    }
    .menu-column {
      flex-basis: 27%;
    }
    .is_back {
      flex-basis: 18% !important;
      padding-left: 4px !important;
    }
    .is_back ~ .buttons-column {
      flex-basis: 18% !important;
    }
    .logo-column {
      flex-basis: 50%;
    }
  }
  @media (max-width: 350px) {
    .buttons-column {
      flex-basis: 23%;
    }
    .menu-column {
      flex-basis: 27%;
    }
    .is_back {
      flex-basis: 18% !important;
      padding-left: 4px !important;
    }
    .is_back ~ .buttons-column {
      flex-basis: 18% !important;
    }
    .logo-column {
      flex-basis: 46%;
      .logo-mobile {
        height: 26px !important;
      }
    }
  }
  @media (max-width: 375px) {
    .buttons-column {
      flex-basis: 23%;
    }
    .menu-column {
      flex-basis: 27%;
    }
    .is_back {
      flex-basis: 18% !important;
      padding-left: 4px !important;
    }
    .is_back ~ .buttons-column {
      flex-basis: 18% !important;
    }
    .navbar-row {
      button {
        width: 36px !important;
      }
    }
  }
  @media (max-width: 330px) {
    .buttons-column {
      flex-basis: 23%;
    }
    .menu-column {
      flex-basis: 27%;
    }
    .is_back {
      flex-basis: 18% !important;
      padding-left: 4px !important;
    }
    .is_back ~ .buttons-column {
      flex-basis: 18% !important;
    }
    .navbar-row {
      button {
        width: 32px !important;
      }
    }
  }
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    .search-input-column {
      justify-content: center;
    }
  }
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    .search-input-column .search-input-container {
      flex-basis: 75%;
    }
  }
  .cart-total-logged {
    .v-badge__badge {
      color: var(--v-grey-darken1) !important;
      padding: 4px 4px;
      @media #{map-get($display-breakpoints, 'md-and-up')} {
        display: none;
      }
    }
  }
}
.shake-enter-active {
  animation: shakeCart 0.4s ease-in-out forwards;
}
@keyframes shakeCart {
  25% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}
.is-cordova {
  .v-progress-linear {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      bottom: 8px !important;
    }
  }
}

.router-back {
  text-decoration: none;
}

.router-back,
.noop {
  .theme--light.v-btn:before {
    background: $white !important;
  }
}
</style>
<script>
import SearchInput from "@/components/navigation/SearchInput.vue";

import login from "~/mixins/login";

import { mapActions, mapState } from "vuex";

export default {
  name: "Navbar",
  mixins: [login],
  components: {
    SearchInput
    // UserMenu
  },
  props: {
    applicationConfig: { type: Object, required: false }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      logout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      // updatedDrawerLeftSite: "app/updatedDrawerLeftSite",
      updatedDrawerRight: "app/updatedDrawerRight",
      updatedDrawerLinks: "app/updatedDrawerLinks"
    }),
    async doLogout() {
      let res = await this.logout();
      if (res) {
        setTimeout(() => {
          this.$router.push({
            name: "HomeSite",
            path: "/"
          });
        }, 200);
      }
    },
    closeLogin() {
      this.$refs.loginMenuRef.isActive = false;
    },
    goBack() {
      this.$router.go(-1);
    },
    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs && this.editableCart) {
            this.$router.push({ name: "Cart" });
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    },
    historyBack() {
      window.history.back();
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      cart: ({ cart }) => cart.cart
    }),
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    }
    // totalItems() {
    //   let total = 0;
    //   forEachCartItem(this.cart, function(item) {
    //     total += item.quantity > 0 ? item.quantity : 1;
    //   });
    //   return total;
    // }
  },
  mounted() {}
};
</script>
